<template>
  <component :is="item === undefined ? 'div' : 'div'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <b-card>
          <b-form>
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  :label="$t('form.slug.label')"
                  label-for="slug"
                >
                  <b-form-input
                    id="slug"
                    v-model="item.slug"
                    :state="errors && errors.slug ? false : null"
                  />

                  <b-form-invalid-feedback v-if="errors && errors.slug">
                    {{ errors.slug[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  :label="$t('form.position.label')"
                  label-for="position"
                >
                  <b-form-input
                    id="position"
                    v-model="item.position"
                    :state="errors && errors.position ? false : null"
                  />

                  <b-form-invalid-feedback v-if="errors && errors.position">
                    {{ errors.position[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  :label="$t('form.status.label')"
                  label-for="status"
                  :state="errors && errors.status ? false : null"
                >
                  <v-select
                    id="status"
                    v-model="item.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="status"
                  />
                  <b-form-invalid-feedback v-if="errors && errors.status">
                    {{ errors.status[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  :label="$t('form.enabled_for_filter.label')"
                  label-for="enabled_for_filter"
                >
                  <b-form-checkbox
                    id="enabled_for_filter"
                    v-model="item.enabled_for_filter"
                  />
                  <b-form-invalid-feedback v-if="errors && errors.enabled_for_filter">
                    {{ errors.enabled_for_filter[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  :label="$t('form.enabled_for_list.label')"
                  label-for="enabled_for_list"
                >
                  <b-form-checkbox
                    id="enabled_for_list"
                    v-model="item.enabled_for_list"
                  />
                  <b-form-invalid-feedback v-if="errors && errors.enabled_for_list">
                    {{ errors.enabled_for_list[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  :label="$t('form.enabled_for_page.label')"
                  label-for="enabled_for_page"
                >
                  <b-form-checkbox
                    id="enabled_for_page"
                    v-model="item.enabled_for_page"
                  />
                  <b-form-invalid-feedback v-if="errors && errors.enabled_for_page">
                    {{ errors.enabled_for_page[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="4"
                class="hidden"
              >
                <b-form-group
                  :label="$t('form.type.label')"
                  label-for="type"
                  :state="errors && errors.type ? false : null"
                >
                  <v-select
                    id="type"
                    v-model="item.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="type"
                  />
                  <b-form-invalid-feedback v-if="errors && errors.type">
                    {{ errors.type[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-tabs pills>
              <b-tab
                v-for="language in languages"
                :key="language.code"
              >
                <template #title>
                  <b-img
                    :src="require('@/assets/images/flags/' + language.code + '.svg')"
                    height="16"
                    width="16"
                    class="mr-1"
                  />
                  <span class="d-none d-sm-inline">{{ language.title }}</span>
                </template>
                <b-row>
                  <b-col
                    cols="24"
                    md="12"
                  >
                    <b-form-group
                      :label="$t('form.title.label')"
                      :label-for="'title.' + language.code"
                    >
                      <b-form-input
                        :id="'title.' + language.code"
                        v-model="item.title[language.code]"
                        :state="errors && errors['title.' + language.code] ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                        {{ errors['title.' + language.code][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
            <b-row>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="onSubmit"
            >
              {{ $t('general.save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              :to="{ name: 'attributes-index' }"
            >
              {{ $t('general.cancel') }}
            </b-button>
          </b-form>
        </b-card>

        <b-card>
          <List
            :attribute-id="item.id"
            :for-page="false"
            :languages="languages"
          />
        </b-card>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import EditorMixin from '@/mixins/EditorMixin'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import List from './values/List'

export default {
  components: {
    List,
  },
  mixins: [GlobalMixin, EditorMixin],
  data() {
    return {
      item: null,
      languages: [],
      typeOptions: [
        { label: this.$t('general.attribute_types.checkbox'), value: 'checkbox' },
        { label: this.$t('general.attribute_types.slider'), value: 'slider' },
      ],
    }
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get(`/api/attributes/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)

        this.$route.meta.breadcrumb.pop()
        this.$route.meta.breadcrumb.push({ text: this.item.title.uk, active: true })
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'attributes-index' })
        }
      })
  },
  methods: {
    onSubmit() {
      const data = {
        position: this.item.position,
        slug: this.item.slug,
        status: this.item.status,
        type: this.item.type,
        enabled_for_filter: this.item.enabled_for_filter,
        enabled_for_list: this.item.enabled_for_list,
        enabled_for_page: this.item.enabled_for_page,
        title: this.item.title,
      }

      this.$http.put(`/api/attributes/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'attributes-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        position: item.position,
        status: item.status,
        type: item.type,
        slug: item.slug,
        enabled_for_filter: item.enabled_for_filter,
        enabled_for_list: item.enabled_for_list,
        enabled_for_page: item.enabled_for_page,
        title: {},
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
      })

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
